.main {
  background: transparent;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    img {
      height: 41px;
    }
  }
  .right {
    button {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      border-radius: 9.6px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: linear-gradient(
        180deg,
        #161616 0%,
        #161616 13%,
        rgba(22, 22, 22, 0) 89%,
        #161616 100%
      );
      color: #ffffff;
      transition: 250ms;
      margin-left: 10px;
      &:hover {
        opacity: 0.8;
      }
      img {
        margin-right: 10px;
        height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .main {
    .logo {
      img {
        height: 31px;
      }
    }
  }
}
