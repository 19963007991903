.main {
  margin-top: 130px;
  background: #000000;
  .flexBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    .leftSection {
      p {
        max-width: 450px;
        color: rgba(216, 213, 209, 0.8);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        margin-top: 10px;
      }
    }
    .rightSection {
      img {
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.13);
        padding: 4px;
        height: 32px;
        width: 32px;
        margin-right: 10px;
      }
    }
  }
  .copyrights {
    padding: 10px 0;
    color: rgba(182, 182, 194, 0.47);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 480;
    font-family: Inter;
    .links {
      a {
        color: rgba(216, 213, 209, 0.65);
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        text-decoration-line: underline;
        margin-right: 10px;
      }
    }
  }
}
@media only screen and (max-width: 900px) {
  .main {
    margin-top: 60px;

    .flexBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      .leftSection {
        text-align: center;
        width: 90%;
        h1 {
          color: #fff;
          font-family: Sora Variable;
          font-size: 31.99px;
          font-style: normal;
          font-weight: 600;
          line-height: 51.185px; /* 160% */
        }
        p {
          max-width: 450px;
          color: rgba(216, 213, 209, 0.8);
          font-family: Sora Variable;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
      .rightSection {
        margin-top: 20px;
        align-items: center;
      }
    }
    .copyrights {
      flex-direction: column;
      .links {
        a {
          color: rgba(216, 213, 209, 0.65);
          font-size: 13px;
          font-weight: 400;
          line-height: 21px;
          text-decoration-line: underline;
          margin-right: 10px;
        }
      }
    }
  }
}
