.bg {
  background: linear-gradient(
      180deg,
      #161616 0%,
      #161616 13%,
      rgba(22, 22, 22, 0) 39%,
      #161616 100%
    ),
    url("/assets/hero/bg.svg");
  background-position: 100% 0px;
  .main {
    padding-top: 150px;
    .titleSection {
      background: url("/assets/hero/main.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 37px;
      .tag {
        color: #ebebeb;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        width: fit-content;
        margin: auto;
        border-radius: 10px;
        background: rgba(87, 72, 255, 0.25);
        padding: 8px 20px;
      }
      h1 {
        text-align: center;
        font-size: 64px;
        font-weight: 400;
        letter-spacing: -3.52px;
        width: fit-content;
        margin: auto;
        margin-top: 15px;
        background-repeat: no-repeat;
        background-position: left;
        background: linear-gradient(
          180deg,
          #fff 0%,
          rgba(255, 255, 255, 0.38) 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &.green {
          div {
            background-image: url("/assets/hero/green.svg");
            background-repeat: no-repeat;
            padding-left: 30px;
            width: 100%;
          }
        }
        &.red {
          div {
            background-image: url("/assets/hero/red.svg");
            background-repeat: no-repeat;
            padding-left: 30px;
            width: 100%;
          }
        }
      }
      p {
        color: rgba(216, 213, 209, 0.8);
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px; /* 166.667% */
        max-width: 600px;
        margin: auto;
        margin-top: 30px;
      }
    }
    .discordBanner {
      margin-top: 50px;
      img {
        width: 100%;
      }
      .web {
        display: block;
      }
      .mobile {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .bg {
    .main {
      margin-top: 100px;
      .titleSection {
        background: none;
        padding: 0px;
        h1 {
          letter-spacing: 0;
          font-size: 24px;
        }
        p {
          font-size: 16px;
          line-height: 27px;
        }
      }
      .discordBanner {
        img {
          width: 100%;
        }
        .web {
          display: none;
        }
        .mobile {
          display: block;
        }
      }
    }
  }
}
