@import "~bootstrap/scss/bootstrap";
@font-face {
  font-family: "Sora Variable";
  src: url("/assets/fonts/Sora/Sora-Variable.ttf") format("truetype");
  font-weight: 100 900;
}

body {
  font-family: "Sora Variable";
  font-weight: 520;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #161616;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
