.main {
  background-image: url("/assets/bluebg.svg");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: 50% -100px;
  padding-top: 200px;
  margin-top: 100px;
  .titleSection {
    .tag {
      color: #ebebeb;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      width: fit-content;
      margin: auto;
      border-radius: 10px;
      background: rgba(87, 72, 255, 0.25);
      padding: 8px 20px;
    }
    h1 {
      margin: auto;
      margin-top: 20px;
      max-width: 900px;
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 70.4px; /* 110% */
      letter-spacing: -2.56px;
      background: linear-gradient(
        162deg,
        rgba(216, 213, 209, 0.6) 0%,
        #f1f0ee 30%,
        #f1f0ee 70%,
        rgba(216, 213, 209, 0.6) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      max-width: 900px;
      margin: auto;
      margin-top: 15px;
      color: rgba(216, 213, 209, 0.8);
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
    }
  }
  .gridContainer {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 40px;
    border-radius: 25px;
    padding: 30px 40px;
    place-content: center;
    align-items: center;
    background: url("/assets/TokenDistribution/Rectangle.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .gridItem {
      h1 {
        font-size: 48px;
        font-weight: 400;
        line-height: 70.4px;
        letter-spacing: -2.56px;
        background: var(
          --linear,
          linear-gradient(
            162deg,
            rgba(216, 213, 209, 0.6) 0%,
            #f1f0ee 30%,
            #f1f0ee 70%,
            rgba(216, 213, 209, 0.6) 100%
          )
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        color: rgba(216, 213, 209, 0.8);
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
      }
      img {
        height: 200px;
      }
      p {
        margin: auto;
      }
    }
  }
  .lowerFlexBox {
    margin-top: 150px;
    background-image: linear-gradient(
        90deg,
        #121212 0%,
        rgba(14, 14, 14, 0) 100%
      ),
      url("/assets/TokenDistribution/bgbox.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 50px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    h1 {
      color: #f1f0ee;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 38.4px; /* 120% */
      letter-spacing: -0.64px;
    }
    p {
      max-width: 550px;
      color: rgba(216, 213, 209, 0.8);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 160% */
    }
    button {
      border-radius: 9.6px;
      border: 1px solid #d8d5d1;
      background: #fff;
      padding: 13px;
      img {
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .main {
    background-position: 50% 100px;
    padding-top: 150px;
    margin-top: 0px;
    .titleSection {
      h1 {
        max-width: 100%;
        font-size: 32px;
        line-height: 70.4px;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .gridContainer {
      margin-top: 50px;
      gap: 20px;
      padding: 15px 10px;
      background: #191919;
      text-align: center;
      border-radius: 25px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      .gridItem {
        h1 {
          font-size: 26px;
          line-height: 130%;
        }
        p {
          font-size: 14px;
          line-height: 130%;
        }
        img {
          height: 150px;
          width: 100%;
        }
        p {
          margin: auto;
        }
        &:nth-last-child(1) {
          grid-column: 1 / -1;
        }
      }
    }
    .lowerFlexBox {
      background: #191919;
      margin-top: 100px;
      padding: 15px 15px;
      flex-direction: column;
      h1 {
        font-size: 22px;
        line-height: 32px;
      }
      p {
        max-width: 550px;
        font-size: 14px;
        line-height: 20px;
      }
      button {
        border-radius: 9.6px;
        border: 1px solid #d8d5d1;
        background: #fff;
        padding: 10px;
        width: 100%;
        img {
          margin-right: 10px;
        }
      }
    }
  }
}
