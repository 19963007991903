.main {
  background-image: url("/assets/bluebg.svg");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: 50% -100px;
  padding-top: 200px;
  margin-top: 100px;
  .titleSection {
    .tag {
      color: #ebebeb;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      width: fit-content;
      margin: auto;
      border-radius: 10px;
      background: rgba(87, 72, 255, 0.25);
      padding: 8px 20px;
    }
    h1 {
      margin: auto;
      margin-top: 20px;
      max-width: 900px;
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 70.4px; /* 110% */
      letter-spacing: -2.56px;
      background: linear-gradient(
        162deg,
        rgba(216, 213, 209, 0.6) 0%,
        #f1f0ee 30%,
        #f1f0ee 70%,
        rgba(216, 213, 209, 0.6) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      max-width: 900px;
      margin: auto;
      margin-top: 15px;
      color: rgba(216, 213, 209, 0.8);
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
    }
  }
  .gridContainer {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    text-align: center;
    .gridItem {
      padding: 30px 30px;
      background: #191919;
      img {
        width: 50%;
      }
      &:nth-child(1) {
        border-radius: 25px 0 0 25px;
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
      &:nth-child(2) {
        border-radius: 0 25px 25px 0;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-left: 0;
      }
      h1 {
        color: #f1f0ee;
        font-size: 32px;
        font-weight: 400;
        line-height: 43px;
        letter-spacing: -0.72px;
        max-width: 90%;
        margin: 20px auto;
      }
      p {
        color: rgba(216, 213, 209, 0.8);
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        max-width: 60%;
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .main {
    background-position: 50% 100px;
    padding-top: 200px;
    margin-top: 0px;
    .titleSection {
      h1 {
        max-width: 100%;
        font-size: 28px;
        line-height: 150%;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .gridContainer {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      .gridItem {
        padding: 20px 10px;
        &:nth-child(1) {
          border-radius: 25px;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }
        &:nth-child(2) {
          border-radius: 25px;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }
        img {
          height: 150px;
        }
        h1 {
          font-size: 22px;
          line-height: 34px;
          margin: 20px 0;
          max-width: 100%;
        }
        p {
          max-width: 100%;
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }
}
