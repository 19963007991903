.main {
  background-image: url("/assets/bluebg.svg");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: 50% -100px;
  padding-top: 200px;
  .titleSection {
    .tag {
      color: #ebebeb;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      width: fit-content;
      margin: auto;
      border-radius: 10px;
      background: rgba(87, 72, 255, 0.25);
      padding: 8px 20px;
    }
    h1 {
      margin: auto;
      margin-top: 20px;
      max-width: 800px;
      text-align: center;
      font-family: Sora Variable;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 70.4px; /* 146.667% */
      letter-spacing: -2.56px;
      background: linear-gradient(
        162deg,
        rgba(216, 213, 209, 0.6) 0%,
        #f1f0ee 30%,
        #f1f0ee 70%,
        rgba(216, 213, 209, 0.6) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .gridContainer {
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    .gridItem {
      h1 {
        color: #ebebeb;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px; /* 125% */
      }
      p {
        color: rgba(216, 213, 209, 0.8);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .main {
    padding-top: 200px;
    background-position: 50% 100px;
    .titleSection {
      h1 {
        max-width: 100%;
        font-size: 28px;
        font-weight: 400;
        line-height: 160%;
      }
    }
    .gridContainer {
      margin-top: 60px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 40px;
      .gridItem {
        img {
          width: 100%;
        }
        h1 {
          color: #ebebeb;
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
        }
        p {
          color: rgba(216, 213, 209, 0.8);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}
